@font-face {
  font-family: "IRANSans-web";
  src: url(/public/fonts/IRANSans-web.eot);
  src: url(/public/fonts/IRANSans-web.eot?#iefix) format("embedded-opentype"),
    url(/public/fonts/IRANSans-web.woff) format("woff"),
    url(/public/fonts/IRANSans-web.woff2) format("woff2"),
    url(/public/fonts/IRANSans-web.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-family: "IRANSans-web" !important;
}

html {
  font-size: 0.85rem;
  direction: rtl;
}

a {
  text-decoration: none;
}

.p-icon.p-submenu-icon {
  transform: rotate(180deg);
}

body {
  margin: 0px;
  height: 100%;
  padding: 0 5rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.main > header.head {
  background-color: #57606f;
  height: 40px;
  padding: 5px;
}

div.main > div.content {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 10px;
}

div.main > div.content > div {
  padding: 0;
}

div.main > div.content > aside {
  border: solid 1px #05a7f8;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: #05a7f8 1px 1px 5px;
}

div.main aside > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
div.main aside > ul > li {
  border-bottom: solid 1px #bdbaba;
}
div.main aside > ul > li > a {
  padding: 10px;
  display: block;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 10px;
  align-items: center;
}

div.main > div.content > div {
  border: solid 1px #05a7f8;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: #05a7f8 1px 1px 5px;
}

.p-float-label {
  margin: 10px 0;
}

.p-float-label > label {
  left: auto;
  right: 1rem;
  color: #888;
}

.p-float-label:has(textarea) label {
  right: 1rem;
}

.p-dropdown-filter-icon {
  right: auto;
  left: 0.75rem;
}

.p-dropdown-filter {
  padding-right: auto;
  margin-right: auto;
  padding-left: 1.75rem;
  margin-left: -1.75rem;
}

.site-ltr {
  direction: ltr;
  display: inline-block;
}

div.p-paginator {
  direction: ltr;
}
