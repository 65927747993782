/* #region article.add-new-queue */
article.add-new-queue {
  margin: 0;
}

article.add-new-queue h2 {
  font-size: 16px;
  border-bottom: solid 1px #0b36f560;
  padding: 10px 0;
}

article.add-new-queue div.buttons {
  margin: 10px 0;
}

/* #endregion */

/* #region ul.locations */
ul.locations {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

ul.locations > li {
  border: solid 1px #888;
  border-radius: 7px;
}

ul.locations > li > a {
  cursor: pointer;
  display: grid;
}

ul.locations > li > a > h3 {
  padding: 5px;
  margin: 0;
  background: rgb(193, 191, 191);
  text-align: center;
  border-radius: 7px 7px 0 0;
}

ul.locations > li > a > div > label::after {
  content: " : ";
}

ul.locations > li > a > div {
  padding: 5px;
}

/* #endregion */

/* #region ul.rooms */
ul.rooms {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

ul.rooms > li {
  height: 300px;
}

ul.rooms > li > a {
  border: solid 1px #888;
  display: block;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  box-shadow: #888 1px 1px 5px;
}

ul.rooms > li > a > img {
  border-radius: 7px;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all ease-in 0.5s 0.1s;
}

ul.rooms > li > a:hover > img {
  transform: scale(1.1);
}

ul.rooms > li > a > span {
  text-align: center;
  background: #00000099;
  padding: 10px;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* #endregion */

/* #region ul.details */
ol.details {
  list-style-type: none;
  display: grid;
  gap: 15px;
  margin: 0;
  padding: 10px 0;
}
ol.details > li {
  border-right: solid 5px #1e0ad0;
  border-bottom: dashed 1px #1e0ad099;
  display: grid;
  padding: 10px;
}

ol.details > li > label {
  color: #888;
}

ol.details > li > span {
  font-weight: bold;
}

/* #endregion */

/* #region table.calendar */
table.calendar {
  width: 100%;
  border-spacing: 0cap;
}

table.calendar tr > th,
table.calendar tr > td {
  border: solid 1px #888;
}

table.calendar tr > th {
  background: #1d00f8ad;
  color: #fff;
  padding: 5px;
}

table.calendar tr > td {
  text-align: center;
  height: 60px;
}

table.calendar tr > td > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  cursor: pointer;
  transition: all ease-in 0.1s 0.3s;
}

table.calendar tr > td > a.active {
  background: #0b36f599;
}

table.calendar tr > td > a.hover {
  background: #0b36f54e;
}

table.calendar tr > td > a:hover {
  background-color: #4d65ff85;
}

/* #endregion */

/* #region div.services */
div.services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

div.services > table {
  width: 100%;
  border-spacing: 0px;
}

div.services > table th {
  border: solid 1px #888;
  background: #1d00f8ad;
  color: #fff;
  padding: 5px;
}

div.services > table td {
  border: solid 1px #888;
}

div.services > table td > input {
  width: 80px;
  direction: ltr;
  text-align: center;
}

/* #endregion */

/* #region div.properties */
div.properties {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
}

div.properties > div {
  border: solid 1px #888;
  border-radius: 5px;
  display: grid;
  gap: 10px;
  padding: 5px;
}

div.properties > div > label {
  color: #888;
}

div.properties > div > input {
  padding: 5px;
  border: solid 1px #888;
}

/* #endregion */

/* #region table.summary */
table.summary {
  width: 100%;
  border-spacing: 0px;
  margin-bottom: 15px;
}

table.summary th {
  border: solid 1px #888;
  background: #1d00f8ad;
  color: #fff;
  padding: 5px;
}

table.summary td {
  border: solid 1px #888;
}
/* #endregion */
